<template>
  <div>
    <div v-if="ready">
      <div id="chart">
        <apexchart type="bar" height="350" :options="chart2.chartOptions" :series="chart2.series"></apexchart>
      </div>
      <hr />
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import VueApexChart from "vue-apexcharts";
export default {
  components: {
    apexchart: VueApexChart
  },
  props:['datanya','key2','keys'],
  mounted() {
      this.key=this.key2
    setTimeout(() => {
        this.createChart();
    }, 1000);
  },
  methods: {
    createChart() {
      let data = this.datanya;
      data= _.orderBy(data, [this.key],['asc']); 
      console.log(this.key,data)
      let that = this;
      let hasil = {};
    //   console.log("createchart", data);
      const unique = [...new Set(data.map((item) => item[that.key]))];
      unique.forEach((e) => {
        hasil[e] = data
          .reduce(
            (accumulator, currentValue) => accumulator.concat(currentValue),
            []
          )
          .filter((item) => item[that.key] == e).length;
      });
      this.total = hasil;
      that.chart2.series[0].data = Object.values(hasil);
      that.chart2.chartOptions.xaxis.categories = Object.keys(hasil);
      that.ready = true;
    },
  },
  watch: {
    key() {
      this.ready = false;
    //   this.getChart();
      this.$forceUpdate();
      setTimeout(() => {
        this.ready = true;
      }, 1000);
    },
  },
  data() {
    return {
      ready: false,
      total: {},
      key:'',
      //   CHART2
      chart2:{
        series: [{
            data: [400, 430, 448, 470, 540, 580, 690, 1100, 1200, 1380]
          }],
          chartOptions: {
            chart: {
              type: 'bar',
              height: 350
            },
            plotOptions: {
              bar: {
                borderRadius: 4,
                horizontal: true,
              }
            },
            dataLabels: {
              enabled: false
            },
            xaxis: {
              categories: ['South Korea', 'Canada', 'United Kingdom', 'Netherlands', 'Italy', 'France', 'Japan',
                'United States', 'China', 'Germany'
              ],
            }
          },
      }
    };
  },
};
</script>