<template>
  <div>
    <div v-if="ready">
      <div id="chart">
        <apexchart
          type="pie"
          width="380"
          :options="chart1.chartOptions"
          :series="chart1.series"
        ></apexchart>
      </div>
      <hr />
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import VueApexChart from "vue-apexcharts";
export default {
  components: {
    apexchart: VueApexChart
  },
  props:['datanya','key2','keys'],
  mounted() {
      this.key=this.key2
    setTimeout(() => {
        this.createChart();
    }, 1000);
  },
  methods: {
    createChart() {
      let data = this.datanya;
      data= _.orderBy(data, [this.key],['asc']); 
      let that = this;
      let hasil = {};
    //   console.log("createchart", data);
      const unique = [...new Set(data.map((item) => item[that.key]))];
      unique.forEach((e) => {
        hasil[e] = data
          .reduce(
            (accumulator, currentValue) => accumulator.concat(currentValue),
            []
          )
          .filter((item) => item[that.key] == e).length;
      });
      this.total = hasil;
      that.chart1.series = Object.values(hasil);
      that.chart1.chartOptions.labels = Object.keys(hasil);
      that.ready = true;
    },
  },
  watch: {
    key() {
      this.ready = false;
    //   this.getChart();
      this.$forceUpdate();
      setTimeout(() => {
        this.ready = true;
      }, 1000);
    },
  },
  data() {
    return {
      ready: false,
      total: {},
      key:'',
      //   CHART1
      chart1: {
        series: [44, 55, 13, 43, 22],
        chartOptions: {
          chart: {
            width: 380,
            type: "pie",
          },
          labels: [],
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
        },
      },
    };
  },
};
</script>