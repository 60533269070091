<template>
  <div>
    <form action="" @submit.prevent="proses()">
    <p class="text-sm text-red-400">Pilih Data Patokan Tanggal : </p>
    <v-select :options="tanggals" v-model="tanggal" ></v-select>
    <p class="text-sm text-red-400">Pilih Data Data Perbandingan : </p>
    <v-select :options="banding" v-model="pembanding" ></v-select>
    <p class="text-sm text-red-400">Pilih Range Report : </p>
    <div class="row">
      <div class="col-6">
        <div class="sm-form ">
            <input type="number" id="tahun" name="tahun" class="form-control form-control-sm" placeholder="tahun" v-model="tahuns" >
        </div>
        <input type="radio" id="tahun" value="tahun" v-model="pilih">
        <label for="tahun">tahun</label>
      </div>
      <div class="col-6">
        <select class='form-control' v-model="bulans">
        <option v-for="(item, index) in 12" :key="index">{{item}}</option>
        </select>
        <input type="radio" id="bulan" value="bulan" v-model="pilih">
        <label for="bulan">bulan</label>
      </div>
    </div>
    <p class="text-sm text-red-400">Pilih Type Report : </p>
    <v-select :options="typereports" label="name" v-model="typereport" :reduce="e => e.id"></v-select>
    <div v-if="typereport=='2'">
        <p class="text-sm">Pilih Data yang dijumlahkan : </p>
        <v-select :options="keys2" v-model="penjumlah" ></v-select>
       
    </div>
    <div v-if="typereport=='1'">
      <p class="text-sm">Tampilkan Sisa Data : </p>
      <!-- note : checkedNames harus dalam bentuk array -->
      <input class="ml-2" type="checkbox" id="sisa" value="sisa" v-model="sisadata2">
      <label for="sisa">sisa</label>
      <input class="ml-2" type="checkbox" id="penambahan" value="penambahan" v-model="sisadata2">
      <label for="penambahan">penambahan</label>
      <input class="ml-2" type="checkbox" id="pengurangan" value="pengurangan" v-model="sisadata2">
      <label for="pengurangan">pengurangan</label>
      <input class="ml-2" type="checkbox" id="tengah" value="tengah" v-model="sisadata2">
      <label for="tengah">tengah</label>
     
    </div>
     <div class="sm-form ">
          <label for="nilai_awal">Nilai Awal : (bisa berupa modal uang awal, atau stock gudang kosong)</label>
          <input type="number" id="nilai_awal" name="nilai_awal" class="form-control form-control-sm" placeholder="nilai_awal" v-model="nilai_awal" >
      </div>
     <p class="text-sm">Tampilan order sisa: </p>
     <input class="ml-2" type="radio" id="asc" value="asc" v-model="asc">
      <label for="asc">asc</label>
      <input class="ml-2" type="radio" id="desc" value="desc" v-model="asc">
      <label for="desc">desc</label>
      <br>
       <p class="text-sm">Tampilan Chart: </p>
     <input class="ml-2" type="radio" id="line" value="line" v-model="charttype">
      <label for="line">line</label>
      <input class="ml-2" type="radio" id="bar" value="bar" v-model="charttype">
      <label for="bar">bar</label>
      <input class="ml-2" type="radio" id="area" value="area" v-model="charttype">
      <label for="area">area</label>
      <br>
    <button type="submit" class="btn btn-sm btn-dark  ">Muat Report</button>
    </form>
    <div v-if="ready">
      <div id="chart">
        <apexchart
          :type="charttype"
          :options="chart1.chartOptions"
          :series="chart1.series"
        ></apexchart>
      </div>
      <div class="mt-3 text-sm">
        <p>Total Data : </p>
        <div v-for="(item,key, index) in total_uniq_data" :key="index+'total_uniq_data'">
          <span class="font-bold">
         {{key}} 
          </span>
         = {{item}}
        </div>
      </div>
      <div id="chart">
        <apexchart
          type="bar"
          :options="chart2.chartOptions"
          :series="chart2.series"
        ></apexchart>
      </div>
      <hr />
    </div>
    <div v-else>
      <div style="height:100px;" class="d-flex justify-content-center align-items-center ">
          <div>
              <p class="animated bounce infinite font-bold text-2xl">Loading ...</p>
          </div>
      </div>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import axios from 'axios';
import VueApexChart from "vue-apexcharts";
export default {
  components: {
    apexchart: VueApexChart
  },
  props:['datanya','datanya2','key2','keys'],
  mounted() {
    console.log(this.bulanya(new Date()))
    this.bulans=parseInt(this.bulanya(new Date()))
    // this.proses()
    this.getData()
    //   this.key=this.key2
    // setTimeout(() => {
    //     this.createChart();
    // }, 1000);
  },
  methods: {
     bulanya(tanggal) {
          return this.$datefns.format(
            new Date(tanggal),
            "MM"
          );
        },
    getData(){
      let that=this;
      setTimeout(() => {
        let keys=Object.keys(this.datanya[0]);
        this.tanggals=keys.filter(e=>{
          if(this.datanya[0][e].toString().indexOf('-')!=-1){
            if(Date.parse(this.datanya[0][e])!='NaN'){
              return e
            }
          }
        })
        setTimeout(() => {
          let array1 = keys.filter(val => !that.tanggals.includes(val));
           console.log(array1)
           this.keys2=array1
           this.keys=array1
           this.keys2=this.keys.filter(e=>{
                if(this.isNumeric(this.datanya[0][e])||typeof this.datanya[0][e]=='number'){
                  return e
                }
           })
            this.banding = this.keys.filter(e=>{
              if(this.datanya[0][e].toString().length<50){
                return e
              }
            })
        }, 1000);
      }, 1000);
    },
     proses(){
      this.ready=false;
      setTimeout(() => {
        let data = this.datanya;
        let key_tanggal=this.tanggal; // dipilih oleh user key penentu tanggal
        let key = this.pembanding; // key/columnt diipilih oleh user;
        let pilih=this.pilih; // tahun,bulan
        let type=this.typereport; // type 1 = total, type 2 = countable
        let sisa_data=this.sisa_data; // true maka hitung selisih dan sisa barang di tiap tanggal
        let nilai_awal=parseInt(this.nilai_awal);
        let keyType = this.penjumlah; // jika type 2 maka pilih key yang dijadikan perhitungan
        let asc = this.asc;
        let bulan=8; // ambil dari selection
        let tahun=2021; // ambil dari selection
        data=data.filter(e=>{ // filter apakah data berdasarkan bulan atau tahun dengan format  yyyy-mm-dd
          if(pilih=='bulan'){
            console.log('pilihbulan',e[key_tanggal].split('-')[1]=='bulan')
              if(e[key_tanggal].split('-')[1]==bulan){
                return e;
              }
          }else if(pilih=='tahun'){
            if(e[key_tanggal].split('-')[0]==tahun){
                return e;
            }
          }
        })
        // ===========DATA DI CHART========
        let total_uniq_data={};
        let series=[];
        let xaxis=[];

        // Mendapatkan data uniq yang ada pada disebuah property
        let uniq_data= _.uniq(data.map(e=>{
          return e[key];
        }));
        console.log('uniq_data',uniq_data)
        const groups = data.reduce((groups, item) => {
          // logikanya cek apakah tanggal di perulangan ini memiliki data date yang sama 
          // apabila ada yang maka skip pembuatan property dengan key date baru dan push ke property sebelumnya
          let date;
          if(pilih=='bulan'){
            date = item[key_tanggal];
          }else if(pilih=='tahun'){
            date = item[key_tanggal].split('-')[1];
          }
          // cek apakah masih memiliki property tanggal yang sama, apabila tidak ada maka create array
          if (!groups[date]) {
            groups[date] = [];
          }
          // apabila masih memiliki property tanggal yang sama maka push ke property array sebelumnya
          groups[date].push(item);
          return groups;
        }, {});
        console.log('groups',groups)
        // lakukan perulangan dengan semua keys tanggal yang sudah didapat
        let groupArrays = Object.keys(groups).map((date) => {
          xaxis.push(date);
          let hasil={
            date, // buat property dan value dengan key tanggal 
            data: groups[date], // buat property baru untuk menjadi holder semua data yang ada pada tanggal tersebut
          };
          // Hitung seberapa banyak data dengan kategori di uniq_data dan di taruh di tiap tanggal data berada
          uniq_data.forEach(e=>{
            // apabila type 2 maka count semua jumlah dari keytype pada kategori tersebut
            if(!hasil[e+"_"+keyType]&&type=='2'){
              hasil[e+'_'+keyType]=0;
            }
            hasil[e]=groups[date].filter(k=>{
              if(k[key]==e){
                if(type=='2'){
                  hasil[e+'_'+keyType]=hasil[e+'_'+keyType]+parseInt(k[keyType])
                }
                return e;
              }
            }).length;
          })
          return hasil
        });
        console.log('uniq_data',uniq_data)
        // ubah series akhir sesuai dengan type inputan
        if(type=='2'){
          uniq_data.forEach(e=>{
            uniq_data.push(e+'_'+keyType);
          })
          uniq_data.splice(0,2)
        }
        // Sort berdasarkan tanggal asc
        groupArrays= _.orderBy(groupArrays, ['date'],['asc']); 
        xaxis= _.orderBy(xaxis, [],['asc']); 
       
        uniq_data.forEach((e,i)=>{
          // build series chart object
          series[i]={
            name:e,
            data:[]
          }
          total_uniq_data[e]=groupArrays.reduce((k,n)=>{
            // push ke series chart object data pada tanggal yang sama
            series[i].data.push(parseInt(n[e]))
            // hitung total data yang dimiliki ditiap uniq_data yang ada
            k=k+parseInt(n[e]);
            return k;
          },0)
          if(type=='2'){
            total_uniq_data[e+'_'+keyType]=groupArrays.reduce((k,n)=>{
              return k+parseInt(n[e+'_'+keyType]);
            },0)
          }
        })
        console.log('xaxis',xaxis);
        console.log('series',series)
        var sum = series[asc=='asc'?0:1].data.map(function (num, idx) {
          return parseInt(num) - parseInt(series[asc=='asc'?1:0].data[idx]);
        });
        var sisa = series[asc=='asc'?0:1].data.map(function (num, idx) {
          return nilai_awal=nilai_awal+(parseInt(num) - parseInt(series[asc=='asc'?1:0].data[idx]));
        });
        var penambahan=[]
        let temp1=nilai_awal;
        for(let i=0;i<series[0].data.length;i++){
            temp1=temp1+series.reduce((e,n)=>{
              return e+n.data[i]
            },0)
            penambahan[i]=temp1;
          }
        var pengurangan=[]
        let temp2=nilai_awal;
        for(let i=0;i<series[0].data.length;i++){
            temp2=temp2+series.reduce((e,n)=>{
              return e-n.data[i]
            },0)
            pengurangan[i]=temp2;
        }
        var tengah =[]
        penambahan.forEach((e,i)=>{
          tengah[i]=(penambahan[i]/2-pengurangan[i]/2);
        })
          // tambahkan antara data
          // series.push({
          //   name:`${series[0].name} - ${series[1].name}`,
          //   data: sum
          // })
          if(this.typereport=='2'){
              series.push({
                name:'sisa'+'_'+keyType,
                data: sisa
              })
            }else{
              if(this.sisadata2.includes('sisa')){
                series.push({
                  name:'sisa'+'_'+keyType,
                  data: sisa
                })
              }
              if(this.sisadata2.includes('penambahan')){
                series.push({
                  name:'Penambahan',
                  data: penambahan
                })
              }
              if(this.sisadata2.includes('pengurangan')){
                series.push({
                  name:'Pengurangan',
                  data: pengurangan
                })
              }
              if(this.sisadata2.includes('tengah')){
               series.push({
                  name:'tengah',
                  data: tengah
                })
              }
        }
        this.total_uniq_data=total_uniq_data;
        console.log('series',series);
        console.log('groupArrays',groupArrays);
        console.log('total_uniq_data',total_uniq_data);
        let totalKey=Object.keys(total_uniq_data);
        let series2=[]
        let series2key=[]
        totalKey.forEach(e=>{
          if(total_uniq_data[e]!=NaN){
            series2key.push(e);
            series2.push({
              name:e,
              data:[total_uniq_data[e]]
            })
          }
        })
        this.chart1.series=series;
        this.chart1.chartOptions.xaxis.categories=xaxis;
        this.chart2.series=series2;
        this.chart2.chartOptions.xaxis.categories=series2key;
        console.log('chart2.series',series2)
        console.log('chart2.axis',series2key)
        this.ready=true;
      }, 1000);
    },
    isDate(date) {
     return (new Date(date) !== "Invalid Date") && !isNaN(new Date(date));
    },
    isNumeric(str) {
        if (typeof str != "string") return false // we only process strings!  
        return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
              !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
      }
  },
  watch: {
    key() {
      this.ready = false;
    //   this.getChart();
      this.$forceUpdate();
      setTimeout(() => {
        this.ready = true;
      }, 1000);
    },
  },
  data() {
    return {
      vdata:{},
      ready: false,
      total: {},
      key:'',
      keys2:[],
      tanggals:[],
      typereport:1,
      asc: 'asc',
      sisadata2:[],
      nilai_awal:10,
      pilih:'',
      bulans:8,
      tahuns:2021,
      banding:[],
      pembanding:'',
      penjumlah:'',
      charttype:'line',
      typereports:[
        {
          id:1,
          name:'Report pen-total data'
        },
        {
          id:2,
          name:'Report pen-nambahan(min 2 type kategori)'
        },
      ],
      tanggal:'',
      total_uniq_data:[],
      //   CHART1
      chart1:{
         series: [
           {
              name: "Desktops",
              data: [10, 41, 35, 51, 49, 62, 69, 91, 148]
          }
          ],
          chartOptions: {
            chart: {
              height: 350,
              type: 'area',
              zoom: {
                enabled: true
              }
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              curve: 'straight'
            },
            title: {
              text: '-',
              align: 'left'
            },
            grid: {
              row: {
                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.5
              },
            },
            xaxis: {
              categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
            }
          },
      },
       //   CHART2
      chart2:{
         series: [
           {
              name: "Desktops",
              data: [10, 41, 35, 51, 49, 62, 69, 91, 148]
          }
          ],
          chartOptions: {
            chart: {
              height: 350,
              type: 'bar',
              zoom: {
                enabled: true
              }
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              curve: 'straight'
            },
            title: {
              text: '-',
              align: 'left'
            },
            grid: {
              row: {
                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.5
              },
            },
            xaxis: {
              categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
            }
          },
      },
    };
  },
};
</script>