<template>
  <div>
    <div class="row justify-content-center">
      <div class="col-md-8 p-2">
       
        <div class="p-3 shadow rounded-lg">
          <div class="row justify-content-center">
            <div class="col-3 text-center">
               <p class="text-center font-bold">
                Bentuk Report
              </p>
              <select class='form-control' v-model="typereport">
                <option value="1">Per Kategori data</option>
                <option value="2">Per dengan Tanggal</option>
              </select>
              <div v-if="typereport=='1'">
                <p class="text-center text-xl font-bold p-4">Report</p>
                <p class="text-red-400 text-sm">Pilih Kategori data : </p>
                <v-select
                  :options="keys"
                  v-model="key"
                ></v-select>
              </div>
            </div>
            <!-- REPORT -->
            <div class="col-md-10">
              <div v-if="typereport=='1'">
                <div v-if="ready">
                  <report1 :datanya='datanya'  :key2="key" :keys="keys" />
                  <report2 :datanya='datanya'  :key2="key" :keys="keys" />
                  <report3 :datanya='datanya'  :key2="key" :keys="keys" />
                </div>
              </div>
              <div v-else-if="typereport=='2'">
                <report4 :datanya='datanya'  :key2="key" :keys="keys" />
              </div>
              <div v-else>
                <p class="text-center">Pilih Type Report !</p>
              </div>
            </div>
          </div>
          <!-- TABLE -->
          <div v-if="ready">
            <br>
            <br>
            <vue-json-excel :data="datanya">
              <button type="button" class="btn btn-sm btn-dark">
                Download Data <span class="typcn typcn-chart-area"></span>
              </button>
            </vue-json-excel>
            <div v-if="datanya.length > 0" class="p-3">
              <Btables
                :tables="datanya"
                :hide="['']"
                @selected="ambil"
                ref="btable"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Btables from "@/components/Btables.vue"; //component btable nya
import report1 from "@/components/report/pie1.vue"; //component btable nya
import report2 from "@/components/report/bar1.vue"; //component btable nya
import report3 from "@/components/report/table1.vue"; //component btable nya
import report4 from "@/components/report/bar2.vue"; //component btable nya
import autophp from "@/plugins/autophp_kasir";
let sdb = new autophp();
import axios from 'axios'
import _ from 'lodash'
import VueApexChart from "vue-apexcharts";
export default {
  components: {
    apexchart: VueApexChart,
    Btables,
    report1,
    report2,
    report3,
    report4
  },
  mounted() {
    this.getChart();
    setTimeout(() => {
      this.getData();
    }, 1000);
  },
  methods: {
    ambil(){

    },
    getData() {
      let that=this;
      //  axios.get('http://localhost/auto/dummy.php').then(res=>{
      //   this.datanya=res.data
      //    this.keys=Object.keys(res.data[0]);
      //     this.$forceUpdate();
      // })
      sdb
        .collection("app_kasir_transaksi_nota")
        .doc()
        .select(
          `select tb1.nota,tb3.nama_produk,tb3.barcode,tb2.status,tb2.tanggal_w,tb2.jumlah from app_kasir_transaksi_nota tb1 left join app_kasir_keranjang tb2 on tb1.nota=tb2.nota left join app_kasir_produk tb3 on tb3.id=tb2.id_produk`
        )
        .then((res) => {
          console.log('resnya',res)
            this.datanya = res;
            this.keys=Object.keys(res[0]);
            that.createChart();
      //      this.key=this.keys[1]
            this.$forceUpdate();
        });
    },
    getChart() {
      // let that = this;
      // axios.get('http://localhost/auto/dummy.php').then(res=>{
      //   let data = res.data;
      //     this.datanya2 = data;
      //     that.createChart();
      // })

      // sdb
      //   .collection("autoreport")
      //   .doc()
      //   .select(
      //     `select * from autoreport`
      //   )
      //   .then((res) => {
      //     let data = res;
      //     this.datanya2 = data;
      //     that.createChart();
      //   });
    },
    createChart() {
      let data = this.datanya;
      data= _.orderBy(data, [this.key],['asc']); 
      let that = this;
      let hasil = {};
      // console.log('createchart',data)
      const unique = [...new Set(data.map((item) => item[that.key]))];
      unique.forEach((e) => {
        hasil[e] = data
          .reduce(
            (accumulator, currentValue) => accumulator.concat(currentValue),
            []
          )
          .filter((item) => item[that.key] == e).length;
      });
      this.total=hasil
      that.chart1.series = Object.values(hasil);
      that.chart1.chartOptions.labels = Object.keys(hasil);
      that.chart2.series[0].data = Object.values(hasil);
      that.chart2.chartOptions.xaxis.categories = Object.keys(hasil);
      that.ready = true;
    },
  },
  watch: {
    key() {
      this.ready = false;
      this.getChart();
      this.$forceUpdate();
      setTimeout(() => {
        this.ready = true;
      }, 1000);
    },
  },
  data() {
    return {
      vdata: {},
      btn: "tambah",
      datanya: [],
      key: "",
      keys: ['nama','jk','pendidikan'],
      datanya2: [],
      radio: [],
      ready: false,
      total:{},
      typereport:'',
    };
  },
};
</script>