<template>
  <div>
      <div v-if="ready">
        <hr>
        <!-- TOTAL -->
        <table class="table-sm table table-responsive table-bordered table-hover table-striped">
            <tr class="bg-blue-300">
                <th class="capitalize">{{key}}</th>
                <th>Jumlah</th>
                <th>Persentasi</th>
            </tr>
            <tr v-for="(item,key, index) in total" :key="index+'total'">
                <td style ="word-break:break-all;">{{key}}</td>
                <td style ="word-break:break-all;">{{item}}</td>
                <td style ="word-break:break-all;">{{((parseInt(item)*100)/Object.values(total).reduce((e,n)=>{
                        return e+n;
                    },0)).toFixed(2)}}%
                </td>
            </tr>
            <tr class="bg-yellow-300">
                <td style ="word-break:break-all;" class="font-bold">Total</td>
                <td style ="word-break:break-all;">
                    {{Object.values(total).reduce((e,n)=>{
                        return e+n;
                    },0)}}
                </td>
                <td style ="word-break:break-all;">100%</td>
            </tr>
        </table>
      </div>
  </div>
</template>
<script>
import _ from "lodash";
export default {
  components: {
  },
  props:['datanya','key2','keys'],
  mounted() {
      this.key=this.key2
    setTimeout(() => {
        this.createChart();
    }, 1000);
  },
  methods: {
    createChart() {
      let data = this.datanya;
      data= _.orderBy(data, [this.key],['asc']); 
      let that = this;
      let hasil = {};
    //   console.log("createchart", data);
      const unique = [...new Set(data.map((item) => item[that.key]))];
      unique.forEach((e) => {
        hasil[e] = data
          .reduce(
            (accumulator, currentValue) => accumulator.concat(currentValue),
            []
          )
          .filter((item) => item[that.key] == e).length;
      });
      this.total = hasil;
      that.ready = true;
    },
  },
  watch: {
    key() {
      this.ready = false;
    //   this.getChart();
      this.$forceUpdate();
      setTimeout(() => {
        this.ready = true;
      }, 1000);
    },
  },
  data() {
    return {
      ready: false,
      total: {},
      key:'',
    };
  },
};
</script>